import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { BASE_URL } from "../config";
import DataSpinners from "../Spinner";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from 'react-redux';

const Uploaddocument = () => {

  const [isDarkMode, setIsDarkMode] = useState(true);
  const [options, setOptions] = useState([]);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [kitNumber, setKitNumber] = useState("");
  const [kitNumbers, setKitNumbers] = useState([]);
  const [reportNumber, setReportNumber] = useState("");
  const [isManualEntry, setIsManualEntry] = useState(false);

  const [documentType, setDocumentType] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select an option");
  const [files, setFiles] = useState(null);
  const [document, setDocument] = useState("");
  const creatorEmail = useSelector((state) => state.admin_email);

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get(`${BASE_URL}/api/get_users_option`, { params: { creatorEmail } });
      setOptions(response.data.users);
    }
    getUsers();
  }, []);
  console.log("document type ", documentType)


  useEffect(() => {
    const getReports = async (email) => {
      try {
        const response = await axios.post(`${BASE_URL}/api/getReportsByEmail`, { email });
        if (response.data.data.length > 0) {
          
          const uniqueKitNumbers = [...new Set(response.data.data.map((report) => report.kitnumber))];
          setKitNumbers(uniqueKitNumbers); 
        } else {
          setKitNumbers([]); // Clear kitNumbers if no data is found
        }
      } catch (error) {
        console.error("Error fetching reports by email", error);
      }
    };
  
    if (selectedOption !== "Select an option") {
      getReports(selectedOption); // Call API when email is selected
    }
  }, [selectedOption]);

  const handleManualKitNumber = (value) => {
    setKitNumber(value); // Set the manually entered kit number directly as kitNumber
  };


  console.log("kitnumber", kitNumber)

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleKitNumber = (e) => {

    setKitNumber(e.target.value);

  }


  const handleReportNumber = (e) => {
    setReportNumber(e.target.value);
  }
  console.log("report number", reportNumber)

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    setSearchTerm("");
  };

  const handleDocumentType = (e) => {
    setDocumentType(e.target.value);
  }

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFile = (e) => {
    setFiles(e.target.files);
    setDocument(e.target.files[0]);
  console.log("selected files", e.target.files[0])}
  const handleUser = (e) => {
    //alert("test");
    console.log("handleUser", e.target.value)
    setSearchTerm(e.target.value);
    setKitNumber(""); // Set kitNumber to blank string when email is changed

  }



  // const checkDuplicateDNAData = async () => {
  //   try {
  //     const response = await axios.get(`${BASE_URL}/api/check_dna_data`, {
  //       params: {
  //         email: selectedOption,
  //         kitNumber: kitNumber,
  //       },
  //     });
  //     return response.data.exists;
  //   } catch (error) {
  //     console.error("Error checking for duplicate DNA Raw data", error);
  //     return false;
  //   }
  // }


  const handleSubmit = async () => {
    if (!selectedOption || !kitNumber || !reportNumber || !documentType || !document) {
      toast.error("All fields are required");
      return;
    }
    setLoading(true);

    if (documentType === "DNA Raw Data") {
      try {
        const checkResponse = await axios.post(`${BASE_URL}/api/check_document_exists`, {
          email: selectedOption,
          kitNumber: kitNumber,
          documentType: documentType,
        });

        if (checkResponse.data.exists) {
          setLoading(false);
          toast.error("A DNA Raw Data document for this email and kit number already exists.");
          return;
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred while checking document existence.");
        return;
      }
    }


    const formData = new FormData();
    formData.append('file', document);
    formData.append('kitNumber', kitNumber);
    formData.append('documentType', documentType);
    formData.append('email', selectedOption);
    formData.append('reportNumber', reportNumber);
    console.log("formdata",formData);
    const response = await axios.post(`${BASE_URL}/api/upload_and_mint`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (response.data.status === 'error') {
      setLoading(false);
      toast.error(response.data.message);
    } else {
      setLoading(false);
      toast.success(response.data.message);
    }
  }


  return (
    <div className={`relative ${isDarkMode ? "dark" : "light"}`}>

      <Toaster />
      {loading && <DataSpinners />}
      <div className="">
        <img
          src="../assets/Ellipse 271.png"
          className=" absolute bottom-0 right-0 "
        />
      </div>
      <Header toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />

      <div
        className={`flex min-h-screen font-inter pt-5 ${isDarkMode ? "bg-[#05295A]" : "bg-white"
          } `}
      >
        <Sidebar isDarkMode={isDarkMode} />
        <div className="lg:w-[70%] w-full mx-auto  z-10">
          <div
            className={` w-full h-screen md:h-full overflow-hidden overflow-y-auto md:overflow-hidden rounded-md p-5 md:p-10 `}
            data-aos="fade-zoom-in"
          >
            {/* Image and input */}
            <div className="flex md:flex-row flex-col justify-center items-center gap-10">
              <div className="md:w-[40%] flex justify-center">
                <img src="../assets/Group 427320849.png" className="w-10/12 " />
              </div>

              {/* user details */}
              <div className="md:w-[60%] w-full">
                <div className="flex justify-center items-center gap-3">
                  <p
                    className={`${isDarkMode ? "text-white" : "text-black"
                      } md:text-2xl text-xl font-bold`}
                  >
                    {" "}
                    User Details
                  </p>
                  <img src="../assets/As1.png" className="w-8" />
                </div>

                {/* kit number */}


                {/* email id */}
                <div
                  className={` ${isDarkMode
                    ? "border border-white/10"
                    : "border border-black/40"
                    } flex items-center gap-5 rounded-md p-3 mt-5`}
                >
                  <div className="flex flex-col">
                    <label
                      className={` ${isDarkMode ? "text-white" : "text-black"
                        } font-semibold `}
                    >
                      Email ID
                    </label>
                    <div className=" w-full">
                      <button
                        onClick={toggleDropdown}
                        className={`w-full bg-transparent text-sm py-2 rounded-lg mt-2 focus:outline-none ${isDarkMode ? "text-white" : "text-black"
                          }`}
                      >
                        {selectedOption}
                      </button>
                      {isOpen && (
                        <div className="absolute w-[50%] mx-auto mt-1 bg-white border rounded-lg shadow-lg max-h-52 overflow-auto z-10">
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={handleUser}
                            placeholder="Search..."
                            className="w-full p-2 border-b focus:outline-none"
                          />
                          {filteredOptions.map((option) => (
                            <div
                              key={option}
                              className="cursor-pointer p-2 hover:bg-gray-200"
                              onClick={() => handleOptionClick(option)}
                            >
                              {option}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`${isDarkMode ? "border border-white/10" : "border border-black/40"
                    } mt-7 flex flex-col gap-5 rounded-md p-3`}
                >
                  <label className={`${isDarkMode ? "text-white" : "text-black"} font-semibold`}>
                    Kit Number
                  </label>

                  {/* Dropdown to select a kit number */}
                  <select
                    className={`${isDarkMode ? "text-white" : "text-black"
                      } w-full bg-transparent focus:outline-none py-2 text-sm`}
                    value={kitNumber}
                    onChange={(e) => {
                      if (e.target.value === "manual") {
                        setIsManualEntry(true); // Trigger manual entry mode
                        setKitNumber(""); // Reset the kit number for manual input
                      } else {
                        setIsManualEntry(false); // Disable manual entry mode
                        setKitNumber(e.target.value); // Set the selected kit number from dropdown
                      }
                    }}
                  >
                    <option value="" className="text-black py-2 text-lg mt-2">
                      Select a kit number
                    </option>
                    {kitNumbers.map((kit) => (
                      <option key={kit} value={kit} className="text-black py-2 text-lg">
                        {kit}
                      </option>
                    ))}
                    <option value="manual" className="text-black text-lg">
                      Add manually
                    </option>
                  </select>

                  {/* Input field to manually type kit number */}
                  {isManualEntry && (
                    <input
                      type="text"
                      placeholder="Enter kit number"
                      value={kitNumber} // Keep the manually entered value
                      onChange={(e) => handleManualKitNumber(e.target.value)} // Handle manual input
                      className={`${isDarkMode ? "text-white" : "text-black"
                        } w-full bg-transparent focus:outline-none py-2`}
                    />
                  )}
                </div>


                <div
                  className={` ${isDarkMode
                    ? "border border-white/10"
                    : "border border-black/40"
                    } mt-7 flex items-center gap-5 rounded-md p-3`}
                >
                  <div className="flex flex-col">
                    <label
                      className={` ${isDarkMode ? "text-white" : "text-black"
                        } font-semibold `}
                    >
                      Report Number
                    </label>
                    <input
                      type="text"
                      placeholder="Enter report number"
                      onChange={handleReportNumber}
                      className={` ${isDarkMode ? "text-white" : "text-black"
                        } w-full bg-transparent focus:outline-none py-2 `}
                    />
                  </div>
                </div>

              </div>
            </div>


            <div className="w-[90%] mx-auto flex md:flex-row flex-col gap-5 mt-10">
              <div className="md:w-[40%]">
                <p
                  className={`${isDarkMode ? "text-white" : "text-black"
                    } md:text-2xl text-xl font-bold `}
                >
                  Upload Document
                </p>
                <p
                  className={`mt-2 text-sm ${isDarkMode ? "text-white" : "text-black"
                    } `}
                >
                  Enter User Kit Number To Upload Documents{" "}
                </p>
              </div>
              <div className="flex md:flex-row flex-col items-center gap-5 md:w-[60%]">
                <div className="w-full mt-1">
                  <p
                    className={`${isDarkMode ? "text-white" : "text-black"
                      } text-sm font-semibold`}
                  >
                    Choose Document Type{" "}
                  </p>
                  <select className="w-full text-sm p-2 rounded-lg mt-2 focus:outline-none" onChange={handleDocumentType}>
                    <option className="text-black/50">Choose Document Type</option>
                    <option value="Order Form">Order Form</option>
                    <option value="Consent Form">Consent Form</option>
                    <option value="DNA Raw Data">DNA Raw Data</option>
                    <option value="Genetic Report">Genetic Report</option>
                    <option value="Blood Report">Blood Report</option>
                    <option value="Deliverables">Deliverables</option>
                  </select>
                </div>
                <div className="w-full">
                  {!files && (
                    <div>
                      <label
                        htmlFor="file-upload"
                        className={`${isDarkMode ? "text-white" : "text-black"
                          } text-sm font-semibold `}
                      >
                        Upload Document{" "}
                        <div className="bg-white p-2 mt-2 rounded-md">
                          <p className="text-black/50">Select Document</p>
                        </div>
                      </label>

                      <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        onChange={handleFile}
                      />
                    </div>
                  )}

                  {files && (
                    <div className="text-center border border-white/20 p-5">
                      <FontAwesomeIcon
                        icon={faXmark}
                        className=" cursor-pointer flex justify-end "
                        style={{ color: "#FFF" }}
                        onClick={() => setFiles(false)}
                      />
                      <ul>
                        {Array.from(files).map((file, index) => (
                          <li
                            key={index}
                            className={`${isDarkMode ? "text-white" : "text-black"
                              } text-sm`}
                          >
                            {file.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}


                </div>
              </div>
            </div>
            <div className="flex justify-end mt-5 mx-10">
              <button className={` ${isDarkMode ? "text-white" : "text-black"} font-semibold rounded-lg px-10 py-2 bg-gradient-to-bl from-[#004696] to-[#3AB6FF] `} onClick={handleSubmit}>
                Upload Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uploaddocument;








