import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DataSpinners from "./Spinner";
import { setUserNotificationCount } from "./store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "./config";

export default function Notification() {
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(5);

  const email = useSelector((state) => state.user_email);
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState(false);


  console.log("email", email);
  const [tableEntries, setTableEntries] = useState([]);

  useEffect(() => {
    fetchData();
    dispatch(setUserNotificationCount());

  }, []);

  const fetchData = async () => {
    try {
      setSpinner(true);
      const response = await axios.post(
        `${BASE_URL}/api/fetch_request_access`,
        { to_email: email }
      );
      setTableEntries(response.data);

      const newData = response.data;
      response.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      console.log("response", response);


      const storedLength = parseInt(localStorage.getItem("userNotificationLength")) || 0;
      console.log("storedLength", storedLength);

      if (newData.length > storedLength) {
        dispatch(setUserNotificationCount(newData.length - storedLength));
      }

      // Check for changes in view_status from null to 1 or 0
      const statusChangedCount = newData.reduce((count, entity) => {
        //   const prevEntity = table.find((e) => e.id === entity.id); // Assuming each entity has a unique id
        if (
          entity.view_status === null &&
          (entity.view_status === "1" || entity.view_status === "0")
        ) {
          return count + 1;
        }
        return count;
      }, 0);

      if (statusChangedCount > 0) {
        dispatch(setUserNotificationCount(statusChangedCount));
      }

      localStorage.setItem("userNotificationLength", newData.length);
      setSpinner(false);
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
    }
  };

  console.log("table entries", tableEntries);

  const [loading, setLoading] = useState(null); // Use null to track loading per entity

  const handleAction = async (id, action, from_email) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/approve_access`, {
        id,
        action,
        from_email,
      });
      console.log(response);
      if (response.data.status == "failed") {
        toast.error(response.data.error);
      } else {
        toast.success(response.data.message);
      }
      //fetchData();
    } catch (error) {
      console.error("Error updating access:", error.message);
    }

    //setLoading(kitNumber); // Set loading state for the specific entity

    // Simulate an API call or some async operation
    setTimeout(() => {
      window.location.reload();
      // setTableEntries(tableEntries.map(entity =>
      //   entity.kitnumber === kitNumber ? { ...entity, view_status: status } : entity
      // ));
      // setLoading(null); // Reset loading state

      // // Show toast notification
      // if (status == '0') {
      //   toast.success('Accepted');
      // } else {
      //   toast.error('Rejected');
      // }
    }, 2000);
  };

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = tableEntries.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <div className="md:h-screen overflow-hidden overflow-y-auto w-[100%]">
      <ToastContainer
        style={{
          top: "20%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          position: "fixed",
          zIndex: "9999",
        }}
      />
      <div className="md:w-[95%] bg-white/20 rounded-2xl py-10 px-5 md:p-10">
        <p className=" text-end text-xs px-3 mb-2">
          Show no of entity
          <select
            className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
            onChange={handleDropdownChange}
            value={entitiesPerPage}
          >
            <option className="text-black" value="5">
              5
            </option>
            <option className="text-black" value="10">
              10
            </option>
            <option className="text-black" value="15">
              15
            </option>
          </select>
        </p>
        <div className=" pb-5 rounded-xl overflow-hidden overflow-x-auto ">
          <table className="w-full text-center">
            <thead className="">
              <tr className="text-sm md:text-base">
                <th className="py-5 px-5 md:px-0 text-center">From</th>
                <th className="py-5 px-5 md:px-0 text-center">To</th>

                <th className="py-5 px-5 md:px-0 text-center">Date</th>
                <th className="py-5 px-5 md:px-0 text-center">
                  Notification Type
                </th>
                <th className="py-5 px-5 md:px-0"></th>
              </tr>
            </thead>
            <tbody>
              {tableEntries.length === 0 ? (
                <tr>
                  <td colSpan="4" className="text-center py-4 font-bold">
                    No data found
                  </td>
                </tr>
              ) : (
                currentEntities.map((entity, index) => (
                  <tr
                    key={index}
                    className="h-16 bg-white/20 border-y-4 border-[#37547b] text-xs md:text-sm rounded-md "
                  >
                    <td className="rounded-l-md px-5 md:px-0">
                      {entity.from_email}
                    </td>
                    <td className="rounded-l-md px-5 md:px-0">
                      {entity.to_email}
                    </td>
                    <td className="px-5 md:px-0">
                      {new Date(entity.created_at).toLocaleDateString("en-IN")}
                      {"   -   "}
                      {new Date(entity.created_at).toLocaleTimeString("en-IN")}
                    </td>
                    <td className="px-5 md:px-0">
  {entity?.document_type
    ? entity.document_type
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : "N/A"} {/* Fallback value if document_type is null */}
</td>

                    <td className="px-5 md:px-0 rounded-r-md">
                      <div className="flex justify-center gap-5">
                        {loading === entity.kitnumber && <DataSpinners />}
                        {!loading && (
                          <>
                            {entity.view_status === null && (
                              <>
                                <button
                                  className="text-[#00FF00] font-bold"
                                  onClick={() =>
                                    handleAction(
                                      entity.id,
                                      "1",
                                      entity.from_email
                                    )
                                  }
                                >
                                  Accept
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    className="pl-1"
                                  />
                                </button>
                                <button
                                  className="text-[#FF0000] font-bold"
                                  onClick={() =>
                                    handleAction(
                                      entity.id,
                                      "0",
                                      entity.from_email
                                    )
                                  }
                                >
                                  Decline
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="pl-1"
                                  />
                                </button>
                              </>
                            )}
                            {entity.view_status === "0" && (
                              <span className="text-red-500 font-bold">
                                Rejected
                              </span>
                            )}
                            {entity.view_status === "1" &&
                              entity.is_profile_shared === "1" && (
                                <a
                                  href={entity.report_doc}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ textDecoration: "underline" }}
                                  className="text-green-500 font-bold "
                                >
                                  View Report
                                </a>
                              )}
                            {entity.view_status === "1" &&
                              entity.is_profile_shared !== "1" && (
                                <span className="text-green-500 font-bold">
                                  Accepted
                                </span>
                              )}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
              {/* Add empty rows if needed */}
              {Array.from({ length: emptyRowCount }).map((_, index) => (
                <tr key={`empty-${index}`} className="h-14">
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-2 flex justify-between text-xs px-3">
          <button
            className="bg-white/30 rounded-md px-5 py-1"
            onClick={handlePrevClick}
            disabled={currentPage === 1}
          >
            Prev
          </button>
          <button
            className="bg-white/30 rounded-md px-5 py-1"
            onClick={handleNextClick}
            disabled={currentEntities.length < entitiesPerPage}
          >
            Next
          </button>
        </div>
      </div>

      {spinner ? <DataSpinners /> : ""}

    </div>
  );
}