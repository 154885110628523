import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ visible, Close, onSelectToken }) => {
    const [searchInput, setSearchInput] = useState('');

    if (!visible) return null;

    const tokens = [
        { src: "https://cdn-icons-png.flaticon.com/128/4341/4341162.png", alt: "Genetic Report", label: "Genetic Report" ,name:"genericReport"},
        { src: "https://cdn-icons-png.flaticon.com/128/603/603227.png", alt: "Blood Report", label: "Blood Test" , name:"bloodReport" },
       // { src: "https://cdn-icons-png.flaticon.com/128/9599/9599485.png", alt: "Sugar test", label: "Sugar test",name:"" },
        { src: "https://cdn-icons-png.flaticon.com/128/9098/9098306.png", alt: "DNA Raw Data", label: "DNA Raw Data",name:"dnaRawData" },
        { src: "https://cdn-icons-png.flaticon.com/128/9599/9599485.png", alt: "Consent Form", label: "Consent Form",name:"consentForm" },
        { src: "https://cdn-icons-png.flaticon.com/128/9098/9098306.png", alt: "Order Form", label: "Order Form" ,name:"orderForm"},
        { src: "https://cdn-icons-png.flaticon.com/128/9599/9599485.png", alt: "Deliverables", label: "Deliverables" ,name:"Deliverables"},
    ];

    const filteredTokens = tokens.filter(token =>
        token.label.toLowerCase().includes(searchInput.toLowerCase())
    );

    const handleTokenClick = (token) => {
        onSelectToken(token);
        Close();
    };

    return (
        <div className="rounded-2xl z-50 lg:w-full modal-overlay lg:fixed lg:inset-0 flex flex-col lg:flex-row lg:items-center lg:justify-center lg:bg-black lg:bg-opacity-10 lg:backdrop-blur-md">
            <div className='w-[100%]'>
                <div className='p-2 flex justify-center '>
                    <div className='w-[90%] md:w-[50%] h-[32rem] bg-black rounded-lg px-2 shadow-2xl pb-5 z-30 absolute md:relative'>
                        <div className='flex flex-col gap-4 border-b border-black pb-2 bg-black shadow-xl rounded-t-3xl '>
                            <div className='flex justify-between items-center'>
                                <p className='text-xl text-white font-bold px-5 mt-4'>Select Type</p>
                                <button onClick={Close} className="shadow-xl rounded-lg text-xl text-white font-bold transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg mt-3 mr-2"><FontAwesomeIcon icon={faXmark} /></button>
                            </div>

                            <input
                                type='search'
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                placeholder="Search reports..."
                                className='px-3 mx-3 py-1  outline-none bg-white/20 rounded-lg'
                            />

                            <div className="bg-black" bis_skin_checked={1}>
                                <div className="lg:w-[100%] mt-5" bis_skin_checked={1}>
                                    <div className="options-container max-h-[17rem] overflow-y-auto custom-scrollbar px-3" bis_skin_checked={1}>

                                        {filteredTokens.map((token, index) => (
                                            <div key={index} className="flex items-center space-x-2 mb-4 cursor-pointer border-b border-white/30 text-white font-bold hover:bg-gray-500 p-2" onClick={() => handleTokenClick(token)} bis_skin_checked={1}>
                                                <img src={token.src} alt={token.alt} className="h-5 mr-2" />
                                                {token.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Modal;
